.introbg {
  background-image: url("image.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 270px;
  object-fit: contain;
}

/* Hide background image on screens smaller than 768px */
@media (max-width: 768px) {
  .introbg {
    background-image: url("image.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px;
  }
}
.shadowbottom {
  box-shadow: 0 8px 4px -4px rgba(232, 229, 229, 0.2);
}