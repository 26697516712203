.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.skills-header {
  margin-bottom: 2rem;
}

.skills-title {
  color: #e4e4e4;
  font-size: 2rem;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .skills-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .skills-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  perspective: 1000px;
  width: 15vh;
  height: 15vh;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #393E46;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #B55400;
}

.card-back {
  transform: rotateY(180deg);
}

.card-logo {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.card-stars {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  color: #ffcc00;
}

.star {
  margin-right: 0.1rem;
}
